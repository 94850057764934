// extracted by mini-css-extract-plugin
export var closeSlider = "ArtSlider-module--close-slider--71bhO";
export var fullScreenSlider = "ArtSlider-module--full-screen-slider--pEZoV";
export var homeLink = "ArtSlider-module--home-link--UVi4K";
export var img = "ArtSlider-module--img--Y0Qr4";
export var nextLink = "ArtSlider-module--next-link--xLQKn";
export var prevLink = "ArtSlider-module--prev-link--OBsDj";
export var slide = "ArtSlider-module--slide--ufw5Z";
export var slideCaption = "ArtSlider-module--slide-caption--hvvn7";
export var slideImage = "ArtSlider-module--slide-image--hS43q";
export var slideImageWrap = "ArtSlider-module--slide-image-wrap--1ZwyI";
export var slider = "ArtSlider-module--slider--GzXab";
export var sliderContainer = "ArtSlider-module--slider-container--aAGpj";
export var sliderGrid = "ArtSlider-module--slider-grid--+krNN";
export var sliderInnerContainer = "ArtSlider-module--slider-inner-container--udrFh";
export var sliderNavigationGrid = "ArtSlider-module--slider-navigation-grid--OVQn-";