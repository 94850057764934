import React, { useRef, useCallback, useState } from 'react';
import { Link } from 'gatsby';
import { Navigation } from 'swiper';
import * as styles from './ArtSlider.module.scss';
import { Swiper, SwiperSlide } from 'swiper/react';
import { EffectFade } from 'swiper';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import 'swiper/css';
import 'swiper/css/effect-fade';
import 'swiper/css/navigation';
import { Localise, Keys } from '../../utils/translations';
import iconLeft from '../../assets/svg/arrow-left.svg';
import iconRight from '../../assets/svg/arrow-right.svg';

const ArtSlider = (props) => {
  const { slides, homePage, languageCode } = props;
  const swiperRef = useRef(null);
  const [fsSlider, setFsSlider] = useState(false);

  const handlePrev = useCallback(() => {
    if (!swiperRef.current) return;
    swiperRef.current.swiper.slidePrev();
  }, []);

  const handleNext = useCallback(() => {
    if (!swiperRef.current) return;
    swiperRef.current.swiper.slideNext();
  }, []);

  const renderPrevButton = () => {
    return (
      <button className={[styles.prevLink, 'button hollow'].join(' ')} onClick={handlePrev}>
        <img className="nav-svg-icon icon-prev" src={iconLeft} width={26} alt="" />{' '}
        <span className="hide-for-small-only">{Localise(Keys.PREVIOUS, languageCode)}</span>
      </button>
    );
  };

  const renderHomeButton = () => {
    return (
      <>
        {homePage && (
          <Link to={homePage.uri} className={[styles.homeLink, 'button hollow'].join(' ')}>
            {Localise(Keys.BACK, languageCode)}
          </Link>
        )}
      </>
    );
  };

  const renderNextButton = () => {
    return (
      <button className={[styles.nextLink, 'button hollow'].join(' ')} onClick={handleNext}>
        <span className="hide-for-small-only">{Localise(Keys.NEXT, languageCode)} </span>
        <img className="nav-svg-icon icon-next" src={iconRight} width={26} alt="" />
      </button>
    );
  };

  const showFsSlider = () => {
    setFsSlider(true);
  }
  const closeFsSlider = () => {
    setFsSlider(false);
  }
  return (
    <>
      <div className={[styles.sliderGrid, 'grid-container fluid'].join(' ')}>
        <div className="grid-x">
          <div className="cell">
            <div className={[styles.sliderContainer, fsSlider ? styles.fullScreenSlider : '' ].join(' ')}>
              <div className={styles.sliderInnerContainer}>
                <Swiper
                  className={styles.slider}
                  modules={[EffectFade, Navigation]}
                  effect={'fade'}
                  loop={true}
                  navigation
                  ref={swiperRef}
                >
                  {slides.map((slide, index) => {
                    if (!slide?.slideImage?.localFile) return null;

                    return (
                      <SwiperSlide className={styles.slide} key={`slide-${index}`}>
                        <div className={styles.slideImageWrap} onClick={showFsSlider}>
                          <GatsbyImage
                            className={styles.slideImage}
                            image={getImage(slide.slideImage.localFile)}
                            alt={slide.slideImage.altText}
                            imgClassName={styles.img}
                          />
                        </div>
                        <div
                          className={styles.slideCaption}
                          dangerouslySetInnerHTML={{ __html: slide.slideCaption }}
                        />
                      </SwiperSlide>
                    );
                  })}
                </Swiper>

                <button className={styles.closeSlider} onClick={closeFsSlider}>Close</button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className={[styles.sliderNavigationGrid, 'grid-container fluid'].join(' ')}>
        <div className="grid-x">
          <div className="cell align-center">
            {renderPrevButton()}
            {renderHomeButton()}
            {renderNextButton()}
          </div>
        </div>
      </div>
    </>
  );
};

export default ArtSlider;
