import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/Layout';
import ArtSlider from '../components/SingleArt/ArtSlider';
import YoastSeo from '../components/YoastSeo';
const artPost = (props) => {
  const {
    data: {
        artPost: {
            uri,
            seo,
            artPostContent: {
                artSlides
            }
        },
        archivePage: {
          nodes: artArchive
        }
    },
    pageContext,
  } = props;
  const languageCode = pageContext.languageCode;
  
  return (
    <Layout bodyClass="page-template-single-art" pageContext={pageContext} navbarStyle={'dark'} hideFooter={true}>
      <YoastSeo lang={languageCode.toLowerCase()} {...seo} uri={uri} />
      {/* React Swiper here with artSlides */}
      <ArtSlider slides={artSlides} homePage={artArchive.length ? artArchive[0] : null} languageCode={languageCode}/>
    </Layout>
  );
};

export const query = graphql`
  query ($id: String, $languageCode: WpLanguageCodeEnum) {
    artPost: wpArt(id: { eq: $id }) {
      id
      uri
      seo {
        ...YoastSEOContent
      }
      artPostContent {
        artSlides {
          ... on WpArt_Artpostcontent_ArtSlides_ImageSlide {
            fieldGroupName
            slideCaption
            slideImage {
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData(layout: CONSTRAINED, placeholder: NONE)
                }
              }
            }
          }
        }
      }
    }
    archivePage: allWpPage(
      limit: 1
      filter: {
        template: { templateName: { eq: "Art Archive" } }
        language: { code: { eq: $languageCode } }
      }
    ) {
      nodes {
        title
        uri
      }
    }
  }
`;
export default artPost;
